import React from "react"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import "react-h5-audio-player/src/styles.scss"
import "./src/style/style.scss"

import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react"

export const wrapRootElement = ({ element }) => (
  <ContentfulLivePreviewProvider locale="en-US" debugMode={true}>
    {element}
  </ContentfulLivePreviewProvider>
)
